import './TeamNewButton.scss';
import { defineComponent } from 'vue';
import TeamEdit from '@/components/Teams/TeamEdit';
import { TeamGameType } from '@/types/teams';
import { useToast } from 'vue-toastification';
import { ModalBox } from '@/components/UI';
export default defineComponent({
    name: 'TeamNewButton',
    components: {
        TeamEdit,
        ModalBox
    },
    setup: () => ({
        toast: useToast()
    }),
    data: () => ({
        newTeam: { name: '', members: [], num_slots: 5, game_type: TeamGameType.Beatout, team_level: 0 },
        newMemberName: '',
        editVisible: false
    }),
    computed: {
        validRFID() {
            var _a;
            return !!((_a = this.newTeam.members[0]) === null || _a === void 0 ? void 0 : _a.rfid);
        }
    },
    methods: {
        startNewTeam() {
            this.editVisible = true;
            this.newTeam = { name: '', members: [], num_slots: 5, game_type: TeamGameType.Beatout, team_level: 0 };
        },
        stopNewTeam() {
            this.editVisible = false;
        },
        async addTeam() {
            try {
                await this.$store.direct.dispatch.teams.add(this.newTeam);
                this.toast.success(`Team ${this.newTeam.name} has been created`);
                this.editVisible = false;
            }
            catch (error) {
                this.toast.error('Error creating team ' + error);
            }
        }
    }
});
